<template>
<div>
  <CCard>
    <CCardHeader>
      <h4 style="color:#00A8FF">{{ device.name }}({{ serviceModel }})</h4>
    </CCardHeader>

    <CCardBody>
        <CCol col="12" xl="12">
          <ul class="nav nav-tabs nav-pills" style="position: relative;">
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }" href='#tab3'>제어반 상태</a>
            </li>        
            <li class="nav-item ">
              <a tab class="nav-link" @click.prevent="setActive('tab5')" :class="{ active: isActive('tab5') }" href='#tab5'>그래프 보기</a>
            </li>
            <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab6')" :class="{ active: isActive('tab6') }" href='#tab6'>작업 일지</a>
            </li>        
            <!-- <li class="nav-item">
              <a tab class="nav-link" @click.prevent="setActive('tab7')" :class="{ active: isActive('tab7') }" href='#tab7'>기기 정보</a>
            </li>-->           
            <div class="right"> <i class="fa-regular fa-clock"></i> 최근 측정일 : {{ sensor_created }}</div>
          </ul>
        </CCol>   
    <div class="tab-content">
        <div class="tab-pane" id='tab3' :class="{ 'active show': isActive('tab3') }">
          <ControlPanel :device.sync="device" :device_guid.sync="device_guid" />
        </div>
        <div class="tab-pane" id='tab5' :class="{ 'active show': isActive('tab5') }">
          <HistoryMeasure
              ref="historyMeasure"
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
        <div class="tab-pane" id='tab6' :class="{ 'active show': isActive('tab6') }">
          <HistoryDevice
              ref=historyDevice
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>
        <!--<div class="tab-pane" id='tab7' :class="{ 'active show': isActive('tab7') }">
          <Productinfo
              ref=productinfo
              :device_guid.sync="device_guid"
              :device.sync="device"
          />
        </div>-->
      </div>      
    </CCardBody>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      @hide="hideModal"
      color="warning"
  />
</div>
</template>

<script>

import Device from '@/views/btfs/Device'

import Event from '@/views/device/Event'
import Search from '@/views/device/Search';

import HistoryMeasure from '@/views/device/HistoryMeasure';
import HistoryDevice from '@/views/device/HistoryDevice';

import ControlPanel from '@/views/thcs/ControlPanel'

import moment from "moment";

export default {
  name: 'THCSDevice',
  extends: Device,
  components: {
    Event,
    Search,
    HistoryMeasure,
    HistoryDevice,
    ControlPanel
  },
  data () {
    return {
      activeItem: 'tab3',
      sensor_created: ''
    }
  },
  watch: {
    device() {
      this.sensor_created = this.dateFormatter(this.device.sensor.created_at)
    }
  },
  methods: {
    getDataControl() {
      var self = this;
      var service_type = _.get(this.device, "service_type"); // || submerged
      switch (service_type) {
        default: this.serviceModel = 'THC처리기'
      }
      this.device_image = 'images/gas_flow.png';
    },
    dateFormatter(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      if (this.activeItem === 'tab5')
        return this.$refs.historyMeasure.getDates();
      else
        return this.$refs.historyDevice.getDates();
    },
  }
}
</script>
<style scoped lang="scss">
.right {
  // border: 1px solid red;
  font-size: 16px;
  font-weight: bold;
  // padding-right: 1%;
  margin: 0.2%;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 0px) and (max-width: 920px) {
  .right {
    display: none;
  }
}
</style>
<!--
<style scoped>
.user-wrap {
  position: relative;
  text-align: center;
}
.user-text {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translate(-90%, -350%);
  font-size: 20px;
  text-align: center;
}


</style>
-->